import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/Seo";

const AboutPage = ({data}) => {
    const {wpPage: page} = data;

    function renderImage(image) {
        if (!image) {
            return null;
        }

        return (
            <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt="" imgClassName="rounded"
                         placeholder="blurred"
                         loading="lazy"
            />
        )
    }

    return (
        <Layout>
            <Seo seo={page.seo} />
            {/*<h1 className="page-heading">{page.title}</h1>*/}
            <div className="bg-white">
                <div className="container w-5/6 lg:w-full">
                    <div className="pt-10 lg:pt-20 text-lg">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="pb-20 px-20 mt-5 hidden lg:block">
                                {renderImage(page.acf_contact.imageBlock1)}
                            </div>
                            <div>
                                <h2 className="text-xl font-medium">
                                    {page.acf_contact.titleBlock1}
                                </h2>
                                <div className="prose" dangerouslySetInnerHTML={{__html: page.acf_contact.textBlock1}}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="container w-5/6 lg:w-full">
                    <div className="py-10 lg:py-20 text-lg">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div>
                                <h2 className="text-xl font-medium">
                                    {page.acf_contact.titleBlock2}
                                </h2>
                                <div className="prose" dangerouslySetInnerHTML={{__html: page.acf_contact.textBlock2}}/>
                            </div>
                            <div className="pb-20 px-20 mt-5 hidden lg:block">
                                {renderImage(page.acf_contact.imageBlock2)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AboutPage;

export const AboutPageQuery = graphql`
  query AboutById($id: String!) {
  wpPage(id: { eq: $id }) {
      seo {  
          canonical
          title
          metaDesc
          focuskw
                
      }
      uri
      title
      content
      acf_contact {
      textBlock2
       titleBlock2
       textBlock1
       title
       titleBlock1
        imageBlock1 {
         id
            mediaItemUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
      }
      imageBlock2 {
         id
            mediaItemUrl
            localFile {
              publicURL
               childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
      }
     
        
      }
          acf_content {
acfImage {
 id
            mediaItemUrl
            localFile {
              publicURL
                  childImageSharp {
                     gatsbyImageData(placeholder: BLURRED)
              }
            }
}
}
    }
}`;
